import { Svg, SvgProps } from "./Svg";

function Reddit(props: SvgProps) {
  return (
    <Svg
      width={34}
      height={30}
      viewBox="0 0 34 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-icon
      {...props}
    >
      <path
        d="M33.666 15.032a3.653 3.653 0 00-6.18-2.502 17.817 17.817 0 00-9.635-3.078l1.627-7.807 5.355 1.126a2.502 2.502 0 10.325-1.527L19.028.018a.776.776 0 00-.926.6L16.25 9.303a17.867 17.867 0 00-9.76 3.078 3.653 3.653 0 10-4.028 5.98 7.18 7.18 0 000 1.102c0 5.605 6.53 10.16 14.589 10.16 8.057 0 14.589-4.555 14.589-10.16a7.2 7.2 0 000-1.102 3.654 3.654 0 002.026-3.328zM8.643 17.535a2.503 2.503 0 115.005 0 2.503 2.503 0 01-5.005 0zm14.538 6.881a9.61 9.61 0 01-6.18 1.927 9.61 9.61 0 01-6.181-1.927.675.675 0 01.95-.95 8.182 8.182 0 005.206 1.576 8.208 8.208 0 005.23-1.527.7.7 0 01.975 1.001v-.1zm-.45-4.279a2.502 2.502 0 112.502-2.502 2.503 2.503 0 01-2.527 2.602l.025-.1z"
        fill="currentColor"
      />
    </Svg>
  );
}

export default Reddit;
