import * as React from "react";

function Github(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-icon
      width={44}
      height={44}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.566 6.59a23.925 23.925 0 0110.868 0c2.06-1.308 3.655-1.906 4.816-2.158a6.412 6.412 0 011.497-.163 3.746 3.746 0 01.61.063l.059.013.025.006.01.003.006.002h.002c.002 0 .003 0-.359 1.327l.362-1.326c.396.108.724.388.892.763a9.075 9.075 0 01.478 6.104 9.808 9.808 0 012.21 6.191v.002c0 4.5-1.334 7.54-3.592 9.496-1.647 1.425-3.666 2.166-5.693 2.568.02.088.038.177.053.268.09.537.08 1.173.07 1.786-.002.186-.005.37-.005.549V38.5a1.375 1.375 0 01-2.75 0v-6.416c0-.339.004-.611.007-.838.007-.491.011-.771-.034-1.045-.037-.223-.106-.43-.487-.812a1.375 1.375 0 01.826-2.34c2.475-.265 4.654-.865 6.213-2.215 1.5-1.3 2.641-3.484 2.642-7.416a7.058 7.058 0 00-1.995-4.91 1.375 1.375 0 01-.3-1.442 6.326 6.326 0 00.15-4.006 5.202 5.202 0 00-.313.059c-.853.185-2.3.7-4.388 2.092-.326.217-.73.285-1.11.186a21.175 21.175 0 00-10.673 0c-.38.1-.783.031-1.109-.186-2.088-1.392-3.535-1.907-4.388-2.092a5.187 5.187 0 00-.312-.059 6.326 6.326 0 00.15 4.006c.187.498.07 1.06-.3 1.442a7.058 7.058 0 00-1.996 4.91c0 3.931 1.142 6.117 2.642 7.416 1.56 1.35 3.738 1.95 6.213 2.216a1.375 1.375 0 01.826 2.34c-.367.366-.502.671-.562.982-.07.367-.053.822.037 1.54.008.058.011.114.011.172V38.5a1.375 1.375 0 01-2.75 0v-1.898c-1.362.245-2.55.202-3.602-.092-1.5-.42-2.552-1.302-3.348-2.218-.496-.57-.979-1.273-1.375-1.85-.18-.261-.343-.497-.48-.684-.31-.42-.56-.692-.775-.865l-.66-.33a1.375 1.375 0 011.003-2.549c.406.12.766.301 1.087.52l8.15 4.075v-.441c-.088-.725-.15-1.521.002-2.315.024-.128.054-.253.089-.377-2.018-.403-4.026-1.144-5.666-2.563-2.258-1.956-3.592-4.995-3.592-9.496v-.002a9.808 9.808 0 012.21-6.191 9.075 9.075 0 01.478-6.104c.168-.375.496-.655.892-.763L9.9 5.683a70.774 70.774 0 01-.36-1.327h.003l.006-.002.01-.003.025-.006a1.978 1.978 0 01.213-.04c.119-.017.271-.032.456-.036.371-.009.87.026 1.497.163 1.161.252 2.755.85 4.816 2.157z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Github;
