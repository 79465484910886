import { Svg, SvgProps } from "./Svg";

function Telegram(props: SvgProps) {
  return (
    <Svg
      data-icon
      width={41}
      height={34}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.94 19.77L.809 16.798S-.282 16.354.07 15.35c.072-.208.218-.383.655-.685C2.748 13.252 38.155.524 38.155.524s1.001-.337 1.59-.115a.86.86 0 01.593.642c.063.263.09.534.078.804 0 .237-.032.452-.053.795-.215 3.48-6.672 29.456-6.672 29.456s-.386 1.519-1.767 1.56a2.531 2.531 0 01-1.849-.715C27.36 30.616 17.973 24.307 15.9 22.92a.39.39 0 01-.171-.28c-.028-.147.13-.312.13-.312S32.205 7.8 32.639 6.276c.034-.119-.094-.178-.265-.125-1.076.396-19.896 12.28-21.962 13.592a1.051 1.051 0 01-.471.028z"
        fill="currentColor"
      />
    </Svg>
  );
}

export default Telegram;
