import { Svg, SvgProps } from "./Svg";

function ProjectCatalystLogo(props: SvgProps) {
  return (
    <Svg
      width={142}
      height={39}
      viewBox="0 0 142 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-logo
      {...props}
    >
      <path
        d="M62.7769 20.5666C63.9878 21.5485 64.811 22.9283 65.0999 24.4602H61.4273C61.182 23.827 60.7509 23.2828 60.1906 22.899C59.5973 22.5116 58.9005 22.3132 58.1921 22.33C57.7246 22.3163 57.2602 22.4101 56.8346 22.6042C56.409 22.7982 56.0337 23.0873 55.7374 23.4492C55.0778 24.2987 54.7441 25.3563 54.797 26.4305C54.7429 27.5006 55.0768 28.5542 55.7374 29.3977C56.0353 29.7568 56.4113 30.043 56.8368 30.2345C57.2622 30.4261 57.7258 30.5178 58.1921 30.5028C58.9 30.5213 59.5969 30.3245 60.1906 29.9385C60.7504 29.56 61.1843 29.0229 61.4367 28.3961H65.1093C64.813 29.9226 63.991 31.297 62.7863 32.2803C61.4907 33.2775 59.887 33.7899 58.2532 33.7286C56.9682 33.7515 55.7008 33.4265 54.5854 32.7881C53.5365 32.1817 52.6868 31.283 52.1401 30.2018C51.5462 29.0342 51.243 27.7404 51.2561 26.4305C51.2357 25.1119 51.5358 23.808 52.1307 22.631C52.6774 21.5498 53.5271 20.6511 54.5759 20.0447C55.6923 19.4084 56.959 19.0836 58.2438 19.1042C59.879 19.0489 61.4823 19.5662 62.7769 20.5666Z"
        fill="#293662"
      />
      <path
        d="M73.7993 22.7391C74.4106 23.1194 74.8913 23.6772 75.1771 24.338V22.2924H78.6286V33.6064H75.1771V31.5655C74.8913 32.2263 74.4106 32.7841 73.7993 33.1643C73.1286 33.5714 72.3545 33.7755 71.5703 33.7521C70.6852 33.7667 69.8148 33.525 69.064 33.0562C68.3049 32.5605 67.7004 31.8615 67.3194 31.0389C66.8764 30.072 66.6577 29.0174 66.6799 27.9541C66.6563 26.8876 66.875 25.8297 67.3194 24.8599C67.7019 24.0408 68.3063 23.3452 69.064 22.852C69.8153 22.3848 70.6857 22.1448 71.5703 22.1607C72.3535 22.1346 73.1276 22.3354 73.7993 22.7391ZM70.8885 25.9227C70.4093 26.4853 70.1666 27.2117 70.2113 27.9494C70.1666 28.6871 70.4093 29.4135 70.8885 29.9761C71.1185 30.2192 71.3977 30.4103 71.7074 30.5369C72.0172 30.6634 72.3504 30.7223 72.6848 30.7097C73.0206 30.7199 73.3547 30.658 73.6645 30.5282C73.9744 30.3985 74.2528 30.2038 74.4811 29.9573C74.9659 29.4043 75.2156 28.6838 75.1771 27.9494C75.2206 27.2086 74.9706 26.4804 74.4811 25.9227C74.2516 25.6788 73.9725 25.4867 73.6627 25.3594C73.353 25.2321 73.0195 25.1724 72.6848 25.1844C72.3501 25.1724 72.0167 25.2321 71.7069 25.3594C71.3971 25.4867 71.118 25.6788 70.8885 25.9227Z"
        fill="#293662"
      />
      <path
        d="M87.7654 30.625V33.6063H86.2042C85.0863 33.6765 83.9825 33.326 83.11 32.6236C82.3764 31.9699 82.0049 30.8837 82.0049 29.3695V25.2126H80.4861V22.2924H82.0049V19.5133H85.4753V22.2924H87.7466V25.2126H85.4753V29.4447C85.4557 29.6118 85.4697 29.781 85.5166 29.9426C85.5634 30.1041 85.6421 30.2546 85.748 30.3852C86.0151 30.5926 86.3527 30.6871 86.6885 30.6486L87.7654 30.625Z"
        fill="#293662"
      />
      <path
        d="M96.4648 22.7391C97.0774 23.1191 97.5597 23.6769 97.8473 24.338V22.2924H101.294V33.6064H97.8473V31.5655C97.5597 32.2266 97.0774 32.7844 96.4648 33.1643C95.7941 33.5714 95.02 33.7755 94.2358 33.7521C93.3509 33.7659 92.4807 33.5243 91.7295 33.0562C90.9719 32.5602 90.3689 31.8611 89.9896 31.0389C89.5417 30.0717 89.3214 29.0149 89.3454 27.9494C89.3187 26.8826 89.5375 25.824 89.9849 24.8552C90.3657 24.0364 90.9685 23.3408 91.7248 22.8473C92.4783 22.3816 93.3502 22.1432 94.2358 22.1607C95.0191 22.1346 95.7931 22.3354 96.4648 22.7391ZM93.5775 25.9227C93.0931 26.4826 92.8482 27.2105 92.8957 27.9494C92.8482 28.6883 93.0931 29.4162 93.5775 29.9761C93.8058 30.22 94.0841 30.4118 94.3933 30.5384C94.7024 30.665 95.0353 30.7235 95.3691 30.7097C95.7049 30.7199 96.039 30.658 96.3488 30.5282C96.6587 30.3985 96.9372 30.2038 97.1654 29.9573C97.652 29.405 97.9034 28.6845 97.8661 27.9494C97.9033 27.2053 97.6446 26.4768 97.1466 25.9227C96.9171 25.6788 96.6381 25.4867 96.3283 25.3594C96.0185 25.2321 95.685 25.1724 95.3503 25.1844C95.0192 25.1736 94.6897 25.234 94.3839 25.3613C94.0781 25.4887 93.8031 25.6801 93.5775 25.9227Z"
        fill="#293662"
      />
      <path
        d="M107.379 18.601V33.6064H103.923V18.601H107.379Z"
        fill="#293662"
      />
      <path
        d="M112.857 22.2924L115.556 29.2143L118.091 22.2783H121.924L114.87 38.9859H111.056L113.713 33.169L108.964 22.2924H112.857Z"
        fill="#293662"
      />
      <path
        d="M130.858 23.2235C131.716 23.9407 132.288 24.9418 132.471 26.0449H129.226C129.145 25.6263 128.919 25.2498 128.587 24.9822C128.23 24.7101 127.789 24.5705 127.341 24.5872C127.022 24.5642 126.706 24.6541 126.447 24.8411C126.345 24.9298 126.264 25.0407 126.211 25.1654C126.158 25.2901 126.134 25.4253 126.142 25.5606C126.142 25.7338 126.196 25.9027 126.296 26.0441C126.397 26.1854 126.538 26.2925 126.701 26.3506C127.266 26.5836 127.854 26.7555 128.455 26.8631C129.177 27.0255 129.889 27.2328 130.585 27.4838C131.145 27.697 131.64 28.0499 132.024 28.509C132.447 29.0423 132.662 29.7103 132.631 30.3899C132.641 31.0062 132.457 31.6102 132.104 32.1157C131.717 32.6414 131.191 33.0486 130.585 33.2913C129.838 33.589 129.038 33.733 128.234 33.7145C126.933 33.7809 125.645 33.4264 124.562 32.7035C124.1 32.3582 123.713 31.9232 123.424 31.4246C123.134 30.9261 122.949 30.3742 122.878 29.8021H126.226C126.24 30.0219 126.302 30.2359 126.409 30.4287C126.515 30.6214 126.663 30.7881 126.842 30.9166C127.259 31.1936 127.753 31.3289 128.253 31.3022C128.573 31.326 128.89 31.2286 129.142 31.0294C129.241 30.9384 129.32 30.8271 129.373 30.703C129.426 30.5788 129.451 30.4448 129.447 30.31C129.453 30.1289 129.403 29.9504 129.302 29.7995C129.202 29.6486 129.057 29.5328 128.888 29.4682C128.301 29.2329 127.693 29.0534 127.073 28.9322C126.372 28.7921 125.682 28.6019 125.008 28.3632C124.462 28.1611 123.976 27.8228 123.598 27.3804C123.176 26.8473 122.967 26.1774 123.01 25.4994C123.005 25.0399 123.104 24.5853 123.3 24.1699C123.497 23.7544 123.785 23.389 124.143 23.1012C125.097 22.4141 126.261 22.0814 127.435 22.1607C128.666 22.0981 129.879 22.4748 130.858 23.2235Z"
        fill="#293662"
      />
      <path
        d="M141.194 30.625V33.6063H139.633C138.515 33.6765 137.411 33.326 136.539 32.6236C135.799 31.9684 135.429 30.8837 135.429 29.3695V25.2126H133.91V22.2924H135.429V19.5133H138.894V22.2924H141.166V25.2126H138.894V29.4447C138.876 29.6121 138.89 29.7815 138.938 29.9431C138.986 30.1046 139.065 30.2549 139.172 30.3852C139.438 30.5935 139.776 30.6881 140.112 30.6486L141.194 30.625Z"
        fill="#293662"
      />
      <path
        d="M52.7138 13.1228V16.2592H51.7263V8.47209H54.1997C54.9215 8.41647 55.637 8.64038 56.1982 9.09751C56.4228 9.31921 56.598 9.5859 56.7123 9.88009C56.8266 10.1743 56.8773 10.4893 56.8613 10.8045C56.8793 11.122 56.8272 11.4396 56.7085 11.7346C56.5899 12.0296 56.4076 12.2949 56.1747 12.5114C55.6155 12.954 54.9112 13.172 54.1997 13.1228H52.7138ZM55.4129 11.9001C55.5525 11.7554 55.6605 11.5833 55.7301 11.3947C55.7997 11.2061 55.8294 11.0051 55.8173 10.8045C55.8311 10.6015 55.8022 10.3979 55.7326 10.2068C55.6629 10.0157 55.554 9.84124 55.4129 9.69471C55.0363 9.40552 54.5643 9.26945 54.0916 9.31382H52.7138V12.2857H54.0775C54.5555 12.3324 55.0333 12.1945 55.4129 11.9001Z"
        fill="#293662"
      />
      <path
        d="M60.6608 10.4471C61.0771 10.2053 61.5527 10.0848 62.0339 10.0991V11.1901H61.7236C61.2164 11.1642 60.7165 11.3191 60.3129 11.6274C60.1182 11.8105 59.9681 12.0359 59.8744 12.2862C59.7806 12.5365 59.7456 12.805 59.7721 13.071V16.2592H58.7799V10.1979H59.7862V11.4252C59.9718 11.018 60.2768 10.6768 60.6608 10.4471Z"
        fill="#293662"
      />
      <path
        d="M68.0812 10.4941C68.5387 10.7424 68.9145 11.1181 69.1627 11.5757C69.4351 12.0857 69.5712 12.6575 69.5577 13.2356C69.5711 13.8107 69.435 14.3794 69.1627 14.8861C68.9142 15.3423 68.5384 15.7164 68.0812 15.963C67.5971 16.2102 67.0612 16.3392 66.5176 16.3392C65.974 16.3392 65.4382 16.2102 64.9541 15.963C64.4964 15.7154 64.1194 15.3417 63.8678 14.8861C63.5973 14.3757 63.4644 13.8036 63.4822 13.2262C63.4704 12.6477 63.6081 12.076 63.8819 11.5662C64.1334 11.1095 64.5103 10.7342 64.9682 10.4847C65.4523 10.2374 65.9881 10.1085 66.5317 10.1085C67.0753 10.1085 67.6112 10.2374 68.0953 10.4847L68.0812 10.4941ZM65.5184 11.2465C65.2033 11.4166 64.9462 11.6769 64.7801 11.9942C64.5804 12.3766 64.4832 12.8044 64.4979 13.2356C64.4848 13.6637 64.5819 14.0879 64.7801 14.4676C64.9476 14.7826 65.2044 15.0411 65.5184 15.2106C65.8269 15.3713 66.1697 15.4552 66.5176 15.4552C66.8655 15.4552 67.2083 15.3713 67.5169 15.2106C67.832 15.0412 68.0904 14.7828 68.2599 14.4676C68.4564 14.0875 68.5519 13.6633 68.5373 13.2356C68.5535 12.8048 68.458 12.3771 68.2599 11.9942C68.0921 11.6766 67.8334 11.4163 67.5169 11.2465C67.2083 11.0858 66.8655 11.0018 66.5176 11.0018C66.1697 11.0018 65.8269 11.0858 65.5184 11.2465Z"
        fill="#293662"
      />
      <path
        d="M70.5358 18.2484H70.9543C71.1624 18.2672 71.3696 18.2049 71.5327 18.0744C71.6717 17.9026 71.7376 17.683 71.7161 17.4631V10.1979H72.7318V17.496C72.7318 18.5869 72.1801 19.1324 71.0766 19.1324H70.5358V18.2484ZM71.7208 8.8671C71.6602 8.80955 71.6119 8.74026 71.5789 8.66346C71.546 8.58666 71.529 8.50395 71.529 8.42037C71.529 8.33679 71.546 8.25408 71.5789 8.17728C71.6119 8.10048 71.6602 8.03119 71.7208 7.97364C71.8601 7.85344 72.0402 7.79118 72.224 7.79965C72.3973 7.79486 72.5658 7.85719 72.6942 7.97364C72.7536 8.03187 72.8009 8.10138 72.8331 8.17809C72.8653 8.25479 72.8819 8.33716 72.8819 8.42037C72.8819 8.50357 72.8653 8.58594 72.8331 8.66265C72.8009 8.73936 72.7536 8.80886 72.6942 8.8671C72.5641 8.9802 72.3963 9.04058 72.224 9.03638C72.0331 9.05035 71.8443 8.98965 71.6973 8.8671H71.7208Z"
        fill="#293662"
      />
      <path
        d="M87.3233 10.7386C87.8333 11.1529 88.169 11.7437 88.2638 12.3939H87.2058C87.1465 11.988 86.9304 11.6216 86.6039 11.3735C86.2857 11.1548 85.9137 11.0274 85.5283 11.005C85.1428 10.9827 84.7586 11.0662 84.4173 11.2465C84.108 11.4165 83.8585 11.6775 83.7025 11.9942C83.5121 12.3795 83.42 12.806 83.4345 13.2356C83.42 13.6652 83.5121 14.0917 83.7025 14.477C83.8574 14.7898 84.1011 15.0499 84.4032 15.2247C84.6987 15.3859 85.0306 15.4684 85.3671 15.4645C85.8056 15.4815 86.2367 15.3489 86.5898 15.0883C86.9154 14.8374 87.1312 14.4699 87.1917 14.0632H88.2497C88.1597 14.7163 87.8232 15.3102 87.3092 15.7232C86.7595 16.1463 86.0792 16.3642 85.386 16.3392C84.8465 16.3487 84.3137 16.2192 83.8389 15.963C83.3917 15.7125 83.0261 15.3387 82.7855 14.8861C82.5223 14.3766 82.3913 13.809 82.4046 13.2356C82.3914 12.6592 82.5223 12.0886 82.7855 11.5757C83.0258 11.1217 83.3914 10.7462 83.8389 10.4941C84.3137 10.2379 84.8465 10.1084 85.386 10.1179C86.0849 10.0888 86.7714 10.3088 87.3233 10.7386Z"
        fill="#293662"
      />
      <path
        d="M93.2248 15.3752V16.2592H92.4725C91.9715 16.2991 91.4749 16.1404 91.09 15.8172C90.9165 15.6152 90.7855 15.3804 90.7047 15.1267C90.624 14.8731 90.595 14.6057 90.6197 14.3407V11.049H89.7216V10.1979H90.6291V8.68369H91.6448V10.1979H93.2154V11.0678H91.6448V14.3595C91.6128 14.6452 91.69 14.9325 91.8611 15.1636C92.0743 15.3268 92.3417 15.4025 92.6088 15.3752H93.2248Z"
        fill="#293662"
      />
      <path
        d="M80.646 12.8923C80.6551 12.3998 80.5351 11.9134 80.298 11.4816C80.0605 11.0608 79.7125 10.7128 79.2917 10.4753C78.8196 10.2165 78.2875 10.0867 77.7493 10.0991C77.2113 10.0895 76.68 10.2191 76.2069 10.4753C75.7572 10.7261 75.3897 11.1017 75.1489 11.5569C74.8841 12.0726 74.7531 12.6466 74.768 13.2262C74.7547 13.7996 74.8856 14.3672 75.1489 14.8767C75.3898 15.3306 75.7573 15.7047 76.2069 15.9536C76.68 16.2098 77.2113 16.3394 77.7493 16.3298C78.2216 16.3369 78.6899 16.2423 79.1224 16.0523C79.5052 15.8859 79.8407 15.6269 80.0984 15.2985C80.3562 14.9701 80.5282 14.5828 80.5989 14.1714H79.5315C79.467 14.5663 79.2511 14.9206 78.9296 15.1589C78.5746 15.4151 78.1445 15.5458 77.707 15.5304C77.2025 15.5417 76.7134 15.3562 76.3433 15.0131C76.1449 14.8059 75.9902 14.5609 75.8884 14.2927C75.7866 14.0245 75.7398 13.7386 75.7508 13.4519H80.5989C80.6343 13.2676 80.65 13.08 80.646 12.8923ZM76.3574 11.4487C76.7439 11.1023 77.2494 10.9187 77.7681 10.9361C78.0943 10.9375 78.4159 11.0131 78.7086 11.1571C79.0023 11.3022 79.2462 11.5314 79.4092 11.8155C79.5365 12.0555 79.6103 12.3202 79.6255 12.5914H75.7884C75.8435 12.1571 76.0441 11.7544 76.3574 11.4487Z"
        fill="#293662"
      />
      <path
        d="M36.5329 23.7875H0.498535C1.46119 27.8423 3.76346 31.4539 7.03301 34.0381C10.3026 36.6223 14.3482 38.028 18.5157 38.028C22.6832 38.028 26.7289 36.6223 29.9984 34.0381C33.268 31.4539 35.5703 27.8423 36.5329 23.7875Z"
        fill="#293662"
      />
      <path
        d="M0 20.9381V19.5274C0 14.6174 1.9505 9.90844 5.42242 6.43652C8.89433 2.9646 13.6033 1.0141 18.5133 1.0141C23.4233 1.0141 28.1323 2.9646 31.6042 6.43652C35.0761 9.90844 37.0266 14.6174 37.0266 19.5274V20.9381H28.4824V19.5274C28.4769 17.0096 27.5189 14.5871 25.8009 12.7466C24.0829 10.906 21.732 9.78367 19.2206 9.60504C16.7091 9.42642 14.2231 10.2047 12.2618 11.7836C10.3006 13.3625 9.00943 15.625 8.64769 18.1167H11.535C11.8743 16.3755 12.8515 14.8238 14.2751 13.7653C15.6987 12.7069 17.4661 12.2181 19.2313 12.3946C20.9964 12.5711 22.6321 13.4003 23.8179 14.7196C25.0037 16.039 25.6542 17.7535 25.6421 19.5274V20.9381H22.8207V19.5274C22.8207 18.3937 22.3703 17.3065 21.5687 16.5049C20.7671 15.7033 19.6799 15.2529 18.5462 15.2529C17.4126 15.2529 16.3253 15.7033 15.5237 16.5049C14.7221 17.3065 14.2717 18.3937 14.2717 19.5274V20.9381H5.69459V19.5274C5.69932 16.2532 6.95679 13.1049 9.20903 10.7283C11.4613 8.35178 14.5375 6.92716 17.8068 6.7467C21.076 6.56625 24.2904 7.64364 26.7905 9.75787C29.2907 11.8721 30.887 14.8629 31.2521 18.1167H34.1158C33.7596 14.0955 31.8645 10.3671 28.826 7.7092C25.7875 5.05132 21.8401 3.66921 17.8072 3.85117C13.7744 4.03313 9.96752 5.76511 7.18069 8.68582C4.39386 11.6065 2.8423 15.4905 2.84965 19.5274V20.9381H0Z"
        fill="#293662"
      />
    </Svg>
  );
}

export default ProjectCatalystLogo;
