import { Svg, SvgProps } from "./Svg";

function StackExchange(props: SvgProps) {
  return (
    <Svg
      data-icon
      width={33}
      height={41}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        d="M.334 18.105h32.207v6.63H.334v-6.63zM.334 9.536h32.207v6.631H.334v-6.63zM27.503.667H5.501A5.24 5.24 0 00.334 5.963v1.722h32.207V5.963c0-2.928-2.239-5.296-5.038-5.296zM.334 26.63v1.722a5.24 5.24 0 005.167 5.296H19.28v7.019l6.803-7.019h1.507a5.24 5.24 0 005.167-5.296V26.63H.334z"
      />
    </Svg>
  );
}

export default StackExchange;
