import { memo } from "react";
import styled from "styled-components";
import { iogSocials } from "../../../links";
import { pxToRem } from "@input-output-hk/px-to-rem";
import colors from "../../../tokens/colors";
import Discord from "../../icons/Discord";
import Facebook from "../../icons/Facebook";
import Github from "../../icons/Github";
import Instagram from "../../icons/Instagram";
import LinkedIn from "../../icons/LinkedIn";
import MailingList from "../../icons/MailingList";
import Reddit from "../../icons/Reddit";
import StackExchange from "../../icons/StackExchange";
import Telegram from "../../icons/Telegram";
import Twitter from "../../icons/Twitter";
import YouTube from "../../icons/YouTube";

export const SocialCardOuter = styled.a<{ platform: string }>`
  --social-card-platform-bg: var(
    --social-card-bg-${(props) => props.platform},
    ${colors.secondary.lilacCloud}
  );
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  background: ${colors.primary.white};
  border-radius: ${pxToRem(20)};
  padding: var(--spacing-small);
  color: ${colors.primary.slateGrey};
  min-width: ${pxToRem(180)};
  transition: transform 0.3s ease-out;

  box-shadow: ${pxToRem(-1)} ${pxToRem(2)} ${pxToRem(10)}
      rgba(255, 255, 255, 0.25),
    ${pxToRem(1)} ${pxToRem(2)} ${pxToRem(10)} rgba(43, 78, 167, 0.15);

  @media not all and (hover: none) {
    &:hover {
      transform: scale(1.1);
    }
  }
`;

export const SocialCardImageContainer = styled.div`
  position: relative;
  border-radius: ${pxToRem(15)};
  color: ${colors.primary.white};
  padding-top: 63.52941176%;
  background: var(--social-card-platform-bg);

  & > [data-icon] {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const SocialCardLabel = styled.p`
  margin: ${pxToRem(16, 8)};
  margin-bottom: 0;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(22)};
  font-weight: 400;
`;
export const SocialCardTagline = styled.p`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 ${pxToRem(16, 8, 8)};
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(22)};
`;

/**
 * Sigh...
 *
 * Lots of media queries here to split
 * allow rows of 4, 3, 2 depending of
 * different screen sizes...
 * (doesnt account for font-size)
 */
export const SocialCardGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);

  margin-left: ${pxToRem(-10)};
  margin-right: ${pxToRem(-10)};

  & > ${SocialCardOuter} {
    margin: var(--spacing-default);
    min-width: unset;
  }
  /* We cant use css variables here but.. the idea is... */
  /* calc(
      var(--responsive-nav-min-width) +
      var(--responsive-content-gutter) +
      var(--page-max-width) +
    ) */
  @media (max-width: calc(${pxToRem(290)} + ${pxToRem(100)} + ${pxToRem(
      1180
    )})) {
    /* background: red; */
    grid-template-columns: repeat(5, minmax(20%, ${pxToRem(180)}));
  }
  @media (max-width: ${pxToRem(899)}) {
    /* background: pink; */
    grid-template-columns: repeat(4, 25%);
  }
  @media (max-width: ${pxToRem(780)}) {
    /* background: blue; */
    grid-template-columns: repeat(3, 33.333%);
  }
  @media (max-width: ${pxToRem(560)}) {
    /* background: green; */
    grid-template-columns: repeat(2, 50%);
  }
  @media (max-width: ${pxToRem(370)}) {
    /* background: orange; */
    display: flex;
    flex-wrap: wrap;
    & > ${SocialCardOuter} {
      flex-grow: 1;
      flex-basis: calc(50% - ${pxToRem(10)});
    }
  }
`;

type Props = {
  platform:
    | "facebook"
    | "instagram"
    | "twitter"
    | "linkedin"
    | "youtube"
    | "reddit"
    | "github"
    | "discord"
    | "telegram"
    | "mailinglist"
    | "stackexchange";
  href: string;
  label: React.ReactNode;
  tagline: string;
  icon: React.ReactNode;
};
export const SocialCard = memo(
  ({ platform, href, label, tagline, icon }: Props) => {
    return (
      <SocialCardOuter
        data-platform={platform}
        platform={platform}
        href={href}
        target="_blank"
      >
        <SocialCardImageContainer>{icon}</SocialCardImageContainer>
        <SocialCardLabel>{label}</SocialCardLabel>
        <SocialCardTagline>{tagline}</SocialCardTagline>
      </SocialCardOuter>
    );
  }
);
SocialCard.displayName = "SocialCard";

export const TWITTER: Props = {
  platform: "twitter",
  icon: <Twitter width="50" height="50" strokeWidth={1} />,
  label: (
    <>
      IOHK
      <br /> Twitter
    </>
  ),
  tagline: "Announcements",
  href: iogSocials.twitter
};

export const INSTAGRAM: Props = {
  platform: "instagram",
  icon: <Instagram width="50" height="50" strokeWidth={1} />,
  label: (
    <>
      IOHK
      <br /> Instagram
    </>
  ),
  tagline: "Announcements",
  href: iogSocials.instagram
};

export const LINKEDIN: Props = {
  platform: "linkedin",
  icon: <LinkedIn width="50" height="50" strokeWidth={1} />,
  label: (
    <>
      IOHK
      <br /> LinkedIn
    </>
  ),
  tagline: "Announcements",
  href: iogSocials.linkedin
};

export const REDDIT_IOHK: Props = {
  platform: "reddit",
  icon: <Reddit />,
  label: (
    <>
      IOHK
      <br /> Reddit
    </>
  ),
  tagline: "Discussions",
  href: "https://www.reddit.com/r/cardano/"
};

export const REDDIT_CARDANO_STAKE_POOLS: Props = {
  platform: "reddit",
  icon: <Reddit />,
  label: (
    <>
      Cardano SPO
      <br /> Reddit
    </>
  ),
  tagline: "Discussions",
  href: "https://www.reddit.com/r/CardanoStakePools/"
};

export const REDDIT_CARDANO_DEV: Props = {
  platform: "reddit",
  icon: <Reddit />,
  label: (
    <>
      Cardano DEV
      <br /> Reddit
    </>
  ),
  tagline: "Discussions",
  href: "https://www.reddit.com/r/CardanoDevelopers/"
};

export const YOUTUBE: Props = {
  platform: "youtube",
  icon: <YouTube />,
  label: (
    <>
      IOHK
      <br /> YouTube
    </>
  ),
  tagline: "Announcements",
  href: "https://www.youtube.com/c/IohkIo/videos"
};

export const DISCORD_COMMUNITY: Props = {
  platform: "discord",
  icon: <Discord />,
  label: (
    <>
      Cardano
      <br /> Community Discord
    </>
  ),
  tagline: "Education",
  href: "https://discord.com/channels/386191477333557248/386223132496887808"
};

export const DISCORD_TECHNICAL: Props = {
  platform: "discord",
  icon: <Discord />,
  label: (
    <>
      IOG&apos;s Technical
      <br /> Community Discord
    </>
  ),
  tagline: "Education",
  href: "https://discord.com/invite/cmveaxuzBn"
};

export const TELEGRAM_CARDANO: Props = {
  platform: "telegram",
  icon: <Telegram />,
  label: (
    <>
      Cardano Announcements
      <br /> Telegram
    </>
  ),
  tagline: "Announcements",
  href: "https://t.me/CardanoAnnouncements"
};

export const TELEGRAM_IO: Props = {
  platform: "telegram",
  icon: <Telegram />,
  label: (
    <>
      Telegram, IO SPO
      <br /> Announcements
    </>
  ),
  tagline: "Announcements",
  href: "https://t.me/SPOannouncements"
};

export const TELEGRAM_IO_DEV: Props = {
  platform: "telegram",
  icon: <Telegram />,
  label: (
    <>
      Telegram, IO DEV
      <br /> Announcements
    </>
  ),
  tagline: "Announcements",
  href: "https://t.me/IOdevannouncements"
};

export const FACEBOOK: Props = {
  platform: "facebook",
  icon: <Facebook width="50" height="50" strokeWidth={1} />,
  label: (
    <>
      IOHK
      <br /> Facebook
    </>
  ),
  tagline: "Announcements",
  href: iogSocials.facebook
};

export const STACKEXCHANGE: Props = {
  platform: "stackexchange",
  icon: <StackExchange />,
  label: (
    <>
      Cardano Stack
      <br /> Exchange
    </>
  ),
  tagline: "Education",
  href: "https://cardano.stackexchange.com/"
};

export const GITHUB: Props = {
  platform: "github",
  icon: <Github />,
  label: (
    <>
      Cardano
      <br /> GitHub
    </>
  ),
  tagline: "Education",
  href: "https://github.com/input-output-hk"
};

export const MAILINGLIST: Props = {
  platform: "mailinglist",
  icon: <MailingList />,
  label: (
    <>
      SPO Digest
      <br /> Sign Up
    </>
  ),
  tagline: "Stake Pools",
  href: "https://iohk.us20.list-manage.com/subscribe?u=26d3b656ecc43aa6f3063eaed&id=707be50294"
};

export const CATALYST_ANNOUNCEMENTS: Props = {
  platform: "telegram",
  icon: <Telegram />,
  label: "Catalyst",
  tagline: "Announcements",
  href: "https://t.me/cardanocatalyst"
};

export const CATALYST_MAILINGLIST: Props = {
  platform: "mailinglist",
  icon: <MailingList />,
  label: (
    <>
      Catalyst
      <br /> Sign Up
    </>
  ),
  tagline: "Newsletter",
  href: "https://bit.ly/3dSZJvx"
};

export const DISCORD_CATALYST: Props = {
  platform: "discord",
  icon: <Discord />,
  label: (
    <>
      Catalyst
      <br /> Discord
    </>
  ),
  tagline: "Education",
  href: "https://discord.gg/2RnUtK8"
};

export const socialLinks = [
  TWITTER,
  INSTAGRAM,
  LINKEDIN,
  REDDIT_IOHK,
  YOUTUBE,
  DISCORD_COMMUNITY,
  TELEGRAM_CARDANO,
  FACEBOOK,
  STACKEXCHANGE,
  DISCORD_TECHNICAL,
  TELEGRAM_IO,
  REDDIT_CARDANO_STAKE_POOLS,
  REDDIT_CARDANO_DEV,
  TELEGRAM_IO_DEV,
  GITHUB,
  MAILINGLIST,
  CATALYST_ANNOUNCEMENTS,
  CATALYST_MAILINGLIST,
  DISCORD_CATALYST
] as const;
