import { Svg, SvgProps } from "./Svg";

function MailingList(props: SvgProps) {
  return (
    <Svg
      data-icon
      width={39}
      height={39}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_1631_9694)">
        <path
          d="M38.14 13.518l-5.822-3.487v1.917h-.31l5.165 3.085v22.142H2.491V15.087l5.147-3.085V9.867l-6.096 3.65a1.825 1.825 0 00-.858 1.497V37.23a1.826 1.826 0 001.825 1.826h34.737a1.825 1.825 0 001.826-1.826V15.033a1.825 1.825 0 00-.931-1.515z"
          fill="currentColor"
        />
        <path
          d="M19.833 26.204a2.866 2.866 0 01-1.46-.42L1.085 15.252l.95-1.57 17.286 10.55a1.022 1.022 0 001.022 0l17.286-10.55.95 1.57L21.31 25.784a2.883 2.883 0 01-1.478.42z"
          fill="currentColor"
        />
        <path
          d="M7.621 17.698V2.583h24.424v15.115a3.03 3.03 0 011.825-.585V2.401A1.825 1.825 0 0032.045.575H7.603a1.825 1.825 0 00-1.826 1.826v14.712h.201a3.14 3.14 0 011.643.585z"
          fill="currentColor"
        />
        <path
          d="M11.49 7.311h16.666M11.49 10.962h16.666M11.49 14.613h16.666"
          stroke="currentColor"
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1631_9694">
          <path
            fill="currentColor"
            transform="translate(.666 .667)"
            d="M0 0h38.333v38.333H0z"
          />
        </clipPath>
      </defs>
    </Svg>
  );
}

export default MailingList;
