import React from "react";
import styled from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";
import colors from "../../tokens/colors";
import {
  forPhoneOnly,
  forTabletPortraitDown,
  not
} from "../../tokens/media-queries";
import Button from "../buttons/Button";
import ProjectCatalystLogo from "../icons/ProjectCatalystLogo";
import zIndex from "../../tokens/z-index";

const DashboardProjectCatalystBannerContainer = styled.div`
  position: relative;
  margin-top: ${pxToRem(50)};
  margin-bottom: ${pxToRem(40)};
  text-decoration: none;
  padding: var(--spacing-large);
  background: var(--project-catalyst-bg-color);
  color: ${colors.primary.slateGrey};
  border-radius: ${pxToRem(20)};
  background-image: url("/project-catalyst.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: ${pxToRem(297)};
  display: flex;
  flex-direction: column;
  @media ${forTabletPortraitDown} {
    height: ${pxToRem(440)};
  }
  cursor: pointer;
`;

const DashboardProjectCatalystBannerVideo = styled.video`
  position: absolute;
  border-radius: ${pxToRem(20)};
  z-index: ${zIndex.base};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  z-index: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s linear;
`;

const DashboardBannerElement = styled.a`
  text-decoration: none;
  cursor: pointer;
  z-index: ${zIndex.base + 1};
`;

const LearnMoreButton = styled(Button)`
  margin-top: ${pxToRem(30)};
  margin-left: ${pxToRem(20)};
  width: ${pxToRem(150)};
`;

export const DashboardProjectCatalystHeading = styled.h2`
  color: ${colors.others.catalystBlue};
  font-size: ${pxToRem(40)};
  line-height: ${pxToRem(28)};
  font-weight: 700;
  padding: ${pxToRem(30)} ${pxToRem(20)} ${pxToRem(0)} ${pxToRem(20)};
  margin: 0;
`;

const DashboardBannerText = styled.p`
  padding-left: ${pxToRem(20)};
  color: ${colors.primary.slateGrey};
  @media ${not(forPhoneOnly)} {
    max-width: 60%;
  }
`;

const Logo = styled(ProjectCatalystLogo)`
  position: absolute;
  right: ${pxToRem(35)};
  bottom: ${pxToRem(40)};
`;

type Props = {};
const DashboardProjectCatalystBanner: React.FC<Props> = () => {
  return (
    <DashboardProjectCatalystBannerContainer>
      <DashboardProjectCatalystBannerVideo
        autoPlay
        muted
        loop
        playsInline
        preload="auto"
        onPlaying={(e) => {
          (e.target as HTMLVideoElement).style.opacity = "1";
        }}
      >
        <source src="/project-catalyst.mp4" type="video/mp4" />
      </DashboardProjectCatalystBannerVideo>
      <DashboardBannerElement
        target="_blank"
        href="https://projectcatalyst.io/"
      >
        <DashboardProjectCatalystHeading>
          Get Funded
        </DashboardProjectCatalystHeading>
        <DashboardBannerText>
          Discover the world’s largest decentralized innovation fund—a framework
          for the Cardano community to turn ideas into impactful real-world
          projects.
        </DashboardBannerText>
        <LearnMoreButton size="medium">Learn More</LearnMoreButton>
        <Logo />
      </DashboardBannerElement>
    </DashboardProjectCatalystBannerContainer>
  );
};

export default DashboardProjectCatalystBanner;
