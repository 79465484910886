import { Svg, SvgProps } from "./Svg";

function Discord(props: SvgProps) {
  return (
    <Svg
      data-icon
      width={44}
      height={34}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.796 3.111A34.972 34.972 0 0027.921.333a28.432 28.432 0 00-1.139 2.361 32.417 32.417 0 00-9.764 0 24.7 24.7 0 00-1.222-2.36A35.25 35.25 0 006.907 3.11a37.333 37.333 0 00-6.375 25 35.626 35.626 0 0010.931 5.556 28.362 28.362 0 002.333-3.848 21.867 21.867 0 01-3.722-1.791c.306-.223.597-.459.889-.709a25 25 0 0021.778 0c.291.25.583.486.889.709a21.862 21.862 0 01-3.667 1.791 26.938 26.938 0 002.333 3.848A35.542 35.542 0 0043.2 28.11a37.25 37.25 0 00-6.403-25zM14.713 23.055a4.167 4.167 0 01-3.861-4.402 4.166 4.166 0 013.861-4.43 4.167 4.167 0 013.875 4.402 4.166 4.166 0 01-3.875 4.43zm14.306 0a4.166 4.166 0 01-3.875-4.402 4.167 4.167 0 013.875-4.43 4.167 4.167 0 013.86 4.402 4.166 4.166 0 01-3.86 4.43z"
        fill="currentColor"
      />
    </Svg>
  );
}

export default Discord;
